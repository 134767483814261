.login-container {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media (max-width: 768px) {
  .form-element.login {
    width: 100%;
  }
}