.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 0;
  height: 55px;
  width: 100%;
  background: #f2f1f0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  z-index: 10;
  padding: 0 16px;
}

.footer-title {
  font-size: 12px;
  letter-spacing: 0px;
}

.footer-icons {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.footer-icon {
  transition: all 0.3s ease-in-out;
}

.footer-icon:hover {
  transform: scale(1.1);
}
