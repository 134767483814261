.create-event-container {
  min-height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;
}

.create-event-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 500px;
  width: 100%;
  margin: auto;
}

@media (max-width: 768px) {
  .create-event-container {
    height: auto;
    padding: 16px 0;
  }

  .create-info-container {
    width: 100%;
  }

  .create-event-form {
    display: flex;
    width: 100%;
  }

  .create-event-form button {
    margin: auto;
  }
}
