.notes-container {
  height: 600px;
  width: 400px;
  transition: max-height 0.95s ease, opacity 0.95s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.notes-text-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.notes-text-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.notes-text-form textarea {
  border: 1px solid black;
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding: 10px;
  margin-bottom: 16px;
  border-radius: 5px;
  transition: all 0.25s ease-in-out;
  background-color: rgba(255, 255, 255, 0.25);
}

.notes-text-form textarea:focus {
  background-color: #f2f1f0;
  outline: none;
}

@media (max-width: 768px) {
  .notes-container {
    height: 425px;
    width: calc(100vw - 32px);
  }
}
