.about-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: #f2f1f0;
}

.about-image-container {
  flex-basis: 50%;
}

.about-image {
  overflow: hidden;
  width: auto;
  height: 100%;
  max-height: calc(100dvh - 100px);
  border-top: 1px solid black;
}

.about-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
}

.about-text {
  width: 50%;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 22px;
  text-align: left;
  line-height: 1.75;
  text-wrap: pretty;
}

@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
  }

  .about-text {
    padding: 0;
    flex-basis: 100%;
    text-wrap: wrap;
    font-size: 18px;
    line-height: 1.5;
    width: 100%;
  }

  .about-image {
    flex-basis: 100%;
    padding-right: 0;
    padding-bottom: 16px;
  }
}
