.dash-header-container {
  position: sticky;
  top: 0;
  z-index: 700;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f1f0;
  border-top: 1px solid black;
}

.dash-header-title-container,
.dash-header-links-container {
  z-index: 702;
  border-bottom: 1px solid black;
  width: 100%;
}

.dash-header-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
}

.dash-header-title {
  font-size: 25px;
  text-transform: uppercase;
  color: black;
  font-weight: 700;
}

.dash-header-links-container {
  height: 35px;
}

.dash-header-links nav {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.dash-link-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  transition: all 0.25s ease-in-out;
  text-transform: uppercase;
}

.dash-link-container button {
  text-decoration: none;
  color: black;
  background-color: transparent;
  font-size: 17px;
  font-weight: 700;
  border: none;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}

.dash-link-container:hover {
  background-color: black;
  cursor: pointer;
}

.dash-link-container:hover button {
  color: #f2f1f0;
}

.dash-active-link {
  background-color: black;
}

.dash-active-link button {
  color: #f2f1f0;
  background-color: black;
}

@media (max-width: 768px) {
  .dash-header-title {
    font-size: 18px;
  }

  .dash-link-container button {
    font-size: 12px;
  }
}
