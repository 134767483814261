.attend-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
}

.attend-image-container {
  flex-basis: 50%;
}

.attend-image {
  overflow: hidden;
  width: auto;
  height: 100%;
  border-top: 1px solid black;
}

.attend-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
}

.attend-info-container {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding: 0 32px;
}

.attend-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  line-height: 1.15;
  text-wrap: pretty;
  margin-bottom: 16px;
}

.attend-text-italic {
  font-style: italic;
  text-wrap: pretty;
}

.faq-link {
  text-decoration: underline;
}

.inquiry-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media (max-width: 768px) {
  .attend-container {
    flex-direction: column;
  }

  .attend-image {
    padding-right: 0;
    margin-bottom: 16px;
  }

  .attend-info-container {
    flex-basis: 100%;
    padding: 0;
  }

  .inquiry-form {
    width: 100%;
  }
}
