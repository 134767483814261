.gallery-container {
  background-color: #f2f1f0;
  padding-top: 55px;
  padding-bottom: 32px;
  width: 100vw;
  img {
    border: 1px solid black;
    opacity: 0;
  }
  min-height: calc(100dvh - 55px);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100vw;
  background-color: #f2f1f0;
}

@media (max-width: 768px) {
  .gallery-container {
    padding-bottom: 16px;
  }
}
