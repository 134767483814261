.toastContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #f2f1f0;
}

.toastContainer p {
  color: black;
  font-weight: 600;
  font-family: "Nunito Sans", sans-serif;
  width: 100%;
}
