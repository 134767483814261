.attendee-container {
  padding: 8px;
  border-bottom: 1px solid black;
  max-width: 80vw;
}

.attendee-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.attendee-info-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  flex-grow: 1;
  border-left: 1px solid black;
  padding-left: 8px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 8px;
  border-right: 1px solid black;
  flex-basis: 33%;
  align-items: center;
}

.attendee-name {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 24px;
}

.attendee-email {
  cursor: pointer;
  margin: 8px 0;
  transition: all 0.3s ease-in-out;
}

.attendee-email:hover {
  transform: scale(1.25);
}

.attendee-message {
  font-size: 14px;
  text-align: center;
  flex-basis: 33%;
  padding: 0 8px;
}

@media (max-width: 768px) {
  .attendee-container {
    max-width: 100%;
  }

  .attendee-info {
    display: flex;
    flex-direction: column;
  }

  .attendee-info-right {
    width: 100%;
    border-left: none;
    padding-left: 0;
  }
}
