.events-list {
  overflow-y: auto;
  min-height: calc(100vh - 90px);
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: auto;
}

.events-card {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  color: black;
  height: auto;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px;
}

.events-list .events-card:nth-of-type(2n) {
  border-right: none;
}

.event-info {
  display: flex;
  padding-bottom: 8px;
}

.event-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  text-overflow: ellipsis;
  width: 100%;
  flex: 1;
}

.event-title {
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  width: 75%;
}

.event-details-container {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-details {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 16px;
  flex: 1;
}

.button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 100%;
  flex: 1;
}

.button.event {
  width: auto;
  font-size: 13px;
  margin: 0 4px;
}

.negative-seats {
  font-weight: 600;
}

@media (max-width: 768px) {
  .events-list {
    overflow-y: auto;
    min-height: calc(100vh - 94px);
    display: flex;
    flex-direction: column;
    grid-auto-rows: none;
  }

  .events-card {
    border-right: none;
    height: auto;
  }

  .event-details {
    font-size: 12px;
  }

  .button-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
  }

  .button.event {
    margin-top: 8px;
  }
}
