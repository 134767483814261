.menu-container {
  position: fixed;
  height: 100dvh;
  width: 350px;
  transition: all 0.3s ease-in-out;
  background: rgba(0, 0, 0, 0.5);
  clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
  z-index: 975;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  top: 0;
  backdrop-filter: blur(10px);
}

.menu-container.open {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.menu-close-button,
.menu-login-button {
  position: absolute;
  left: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #f2f1f0;
  text-shadow: 0 1.25px 1.5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.menu-close-button {
  top: 16px;
}

.menu-login-button {
  bottom: 16px;
}

.menu-close-button:hover,
.menu-login-button:hover {
  transform: scale(1.25);
}

.menu-links-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
}

.menu-link-wrapper {
  overflow: hidden;
  width: fit-content;
  height: fit-content;
  margin: -0.25rem;
  padding: 0 32px;
  transition: all 0.3s ease-in-out;
}

.menu-link-wrapper:active,
.menu-link-wrapper:hover {
  transform: scale(1.25);
}

.menu-link {
  font-size: 24px;
  line-height: 2;
  color: #f2f1f0;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.link-wrapper {
  margin: 0;
  padding: 0;
}

.menu-login {
  align-self: flex-start;
}

.menu-login button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #f2f1f0;
}

.menu-login span {
  font-size: 18px;
}

@media (max-width: 768px) {
  .menu-container {
    width: calc(100% + 2px);
  }

  .menu-link {
    font-size: 24px;
  }
}
