.add-guest-container {
  width: 100%;
  padding: 8px;
}

.add-guest-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}
