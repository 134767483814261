.success-container {
  background-color: #f2f1f0;
  padding-top: 55px;
  width: 100vw;
}

.success-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;
  text-align: center;
  gap: 16px;
  min-height: calc(100vh - 110px);

  h2 {
    font-size: 32px;
  }
}
