.dash-container {
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  box-sizing: border-box;
  background-color: #f2f1f0;
}

.events-container {
  min-height: calc(100vh - 90px);
  overflow-y: scroll;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .dash-container {
    width: 100%;
  }
}
