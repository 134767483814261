@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
    line-height: 1.5;
  }
}

@font-face {
  font-family: "Laygrotesk";
  src: url("./fonts/laygrotesk-regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  line-height: normal;
}

@font-face {
  font-family: "Laygrotesk";
  src: url("./fonts/laygrotesk-medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  line-height: normal;
}

@font-face {
  font-family: "Laygrotesk";
  src: url("./fonts/laygrotesk-semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  line-height: normal;
}

@font-face {
  font-family: "Laygrotesk";
  src: url("./fonts/laygrotesk-bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  line-height: normal;
}

@font-face {
  font-family: "Laygrotesk";
  src: url("./fonts/laygrotesk-black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  line-height: normal;
}

@font-face {
  font-family: "Neuzeit Grotesk";
  src: url("./fonts/neuzeit-grotesk.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  line-height: 1.2;
}

@font-face {
  font-family: "Neuzeit Grotesk";
  src: url("./fonts/neuzeit-grotesk-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  line-height: 1.2;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  //font-family: "Playfair Display", serif;
  font-family: "Neuzeit Grotesk", sans-serif;
  font-weight: 400;
  background: black;
  scroll-behavior: smooth;
  text-wrap: pretty;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Reset box-sizing for all elements
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// Improve text rendering
html {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

// Make images responsive by default
img {
  max-width: 100%;
  height: auto;
  display: block;
}

// Better link underlines
a {
  text-decoration-thickness: 1px;
  text-underline-offset: 0.3em;
  color: inherit;
}

// Improve form element consistency
button,
input,
select,
textarea {
  font: inherit;
  color: inherit;
}

// Remove list styles by default
ul,
ol {
  list-style: none;
}

h1,
h2 {
  letter-spacing: -0.06em;
}

p {
  letter-spacing: -0.02em;
}
