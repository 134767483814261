.header-container {
  position: fixed;
  top: 0;
  z-index: 700;
  height: 55px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  mix-blend-mode: difference;
}

.menu-button {
  display: flex;
  padding-left: 16px;
  color: white;
}

.menu-button.open {
  filter: blur(50px);
}

.menu-button:hover {
  cursor: pointer;
}

.header-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 32px;
  width: auto;
  filter: invert(1);
}
