/* GLOBAL STYLES */

/* og cream color: #ebe2d9*/

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f1f0;
  opacity: 0;
  width: 100%;
  position: relative;
}

.page-container-scroll-trigger {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.page-title-container {
  height: 100svh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.splash-image-container {
  width: 100vw;
  height: 100svh;
  flex-grow: 1;
  overflow: hidden;
  min-height: 100svh;
  position: relative;
  border-bottom: 1px solid black;

  /*
  .splash-image-overlay {
    opacity: 0;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background: linear-gradient(
      to bottom,
      rgba(242, 242, 242, 0),
      rgba(242, 242, 242, 1)
    );
    pointer-events: none;
  }
    */
}

.splash-image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.logo-animation-container {
  position: absolute;
  left: 16px;
  bottom: 0;
  overflow: hidden;
}

.page-content {
  padding-top: 32px;
  padding-bottom: 32px;
  height: auto;
  min-height: calc(100svh - 85px - 55px);
  background-color: #f2f1f0;
  z-index: 10;
}

/* For button styling */

.checkout-button,
.checkout-button-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: black;
  padding: 8px 20px;
  border: black 1px solid;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  border-radius: 3px;
  letter-spacing: -0.02em;
}

.checkout-button {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: black;
    transition: left 0.3s ease-in-out;
    z-index: -1;
  }

  &:hover {
    color: #f2f1f0;
    box-shadow: none;

    &::before {
      left: 0;
    }
  }
}

.checkout-button-disabled {
  cursor: not-allowed;
  opacity: 0.25;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: black;
  padding: 8px 20px;
  border: black 1px solid;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  border-radius: 3px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: black;
    transition: left 0.3s ease-in-out;
    z-index: -1;
  }

  &:hover {
    color: #f2f1f0;
    box-shadow: none;

    &::before {
      left: 0;
    }
  }

  &:active {
    box-shadow: 0 0.75px 1.75px rgba(0, 0, 0, 0.5);
  }
}

/* Common styles for input, select, and textarea */
.form-element-container {
  display: flex;
  flex-direction: column;
}

.form-element {
  display: flex;
  -webkit-appearance: none;
  appearance: none;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
  background-color: #f2f1f0;
  font-size: 14px;
  width: 100%;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.02em;
}

.form-element:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.75);
  color: black;
  box-shadow: 0 3.25px 4.5px rgba(0, 0, 0, 0.5);
}

.form-element:hover {
  cursor: pointer;
}

.form-element-disabled {
  width: 100%;
  border: 1px solid black;
  border-radius: 3px;
  padding: 0 10px;
  height: 40px;
  font-size: 14px;
  color: black;
  background: #f2f1f0;
  transition: all 0.3s ease-in-out;
  opacity: 0.5;
  cursor: not-allowed;
  display: flex;
  align-items: center;
}

.text-area-disabled {
  width: 100%;
  border: 1px solid black;
  border-radius: 3px;
  padding: 8px;
  font-size: 14px;
  color: black;
  background: #f2f1f0;
  padding: 10px;
  height: 165px;
  opacity: 0.5;
  cursor: not-allowed;
}

input {
  padding: 0 10px;
  height: 40px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input::active,
textarea::active {
  color: black;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: black;
}

input[type="date"],
input[type="time"] {
  padding: 0 10px;
  height: 40px;
  background-color: #f2f1f0;
  font-size: 14px;
  color: black;
  text-align: left;
}

textarea {
  padding: 10px;
  height: 165px;
  color: black;
}

select {
  height: 40px;
  padding: 0 10px;
  color: black;
  background-color: #f2f1f0;
  letter-spacing: 1px;
  color: black;
  font-size: 16px;
}

/* for label styling */
label {
  font-size: 16px;
  color: black;
  text-transform: uppercase;
  font-weight: 500;
}

.Toastify__toast {
  background: #f2f1f0 !important;
  border: 1px solid black !important;
  border-radius: 3px !important;
  box-shadow: 4px 10px 10px rgba(0, 0, 0, 0.95) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.Toastify__progress-bar {
  background: black !important;
}

@media (min-width: 1200px) {
  .image-container {
    width: 375px;
  }
}

@media (max-width: 768px) {
  .page-left {
    display: none;
  }

  .page-bottom {
    height: auto;
  }

  .button:hover {
    color: black;
    background: rgba(0, 0, 0, 0.1);
  }

  .button:active {
    color: black;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.75px 0.75px rgba(0, 0, 0, 0.5);
  }
}

.container {
  width: 100%;
  max-width: 1536px;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: #f2f1f0;
  z-index: 10;
}

@media (max-width: 1536px) {
  .container {
    max-width: 1280px;
  }
}

@media (max-width: 1280px) {
  .container {
    max-width: 1024px;
  }
}

@media (max-width: 1024px) {
  .container {
    max-width: 768px;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 640px;
  }

  .page-content {
    padding-top: 16px;
    min-height: calc(100dvh - 65px - 55px);
  }
}

@media (max-width: 640px) {
  .container {
    max-width: 100%;
  }
}
