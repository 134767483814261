.home-container {
  width: 100%;
}

.home-media-container {
  width: 100vw;
  height: 100svh;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid black;
}

.home-video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.home-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.home-logo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.home-button-wrapper {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.home-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: #f2f1f0;
  padding: 8px 20px;
  border: #f2f1f0 1px solid;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  border-radius: 3px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    transition: left 0.3s ease-in-out;
    background: black;
    opacity: 0.5;
    z-index: -1;
  }

  &:hover {
    &::before {
      left: 0;
    }
  }

  &:active {
    box-shadow: 0 0.75px 1.75px rgba(0, 0, 0, 0.5);
  }
}

.home-text-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f2f1f0;
  overflow: hidden;
  position: relative;
  will-change: transform;
  max-height: 0;
  height: auto !important;
  overscroll-behavior: none;
}

.home-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 16px 0;
  padding: 32px 0;

  .wrapper-line-top {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 2px;
    background-color: black;
  }

  .wrapper-line-bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 2px;
    background-color: black;
  }
}

.home-text {
  font-size: 32px;
  line-height: 1.15;
  text-wrap: balance;
  text-align: center;
  overflow: hidden;
  width: 80%;
}

@media (max-width: 768px) {
  .home-text-container {
    width: 100%;
  }

  .home-text-wrapper {
    padding: 16px 0;

    .wrapper-line-top {
      height: 1.5px;
    }

    .wrapper-line-bottom {
      height: 1.5px;
    }
  }

  .home-text {
    font-size: 20px;
    text-align: center;
    width: 100%;
  }

  .home-button {
    font-size: 16px;
  }
}
