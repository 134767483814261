.attendance-container {
  min-width: 300px;
  height: auto;
  max-height: 75vh;
  overflow-y: scroll;
  background-color: #f2f1f0;
}

.attendees-container {
  display: grid;
  grid-template-columns: 1;
}

.no-attendees-container {
  height: 300px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: black;
}
